const civitasSettings = {
	title: "Quanta.Report",
	description: "A science and technology-driven news platform that uses quantum computing to analyze vast datasets, helping to uncover emerging scientific discoveries and tech innovations faster than conventional methods.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'rgb(250,250,250)',
	footerBg: "rgb(250,250,250)",
	headerTextColor: "#222",
	footerTextColor: "#222",
	style: 'in depth'
};

export default civitasSettings;
